import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Edification`}</strong>{` is the technique of using `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`, more specifically `}<a parentName="p" {...{
        "href": "/Primal%20Aspects",
        "title": "Primal Aspects"
      }}>{`Memory`}</a>{` and `}<a parentName="p" {...{
        "href": "/Primal%20Aspects",
        "title": "Primal Aspects"
      }}>{`Material`}</a>{` to create `}<a parentName="p" {...{
        "href": "/Edifice",
        "title": "Edifice"
      }}>{`Edifices`}</a>{`, false constructs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      